modules.showcase = (function () {

    var carousels = [
        {
            selector: '.featured-products-slider .top-details',
            initObject: {
                mobileFirst: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false,
                arrows: false,
                infinite: false,
                speed: 500,
                asNavFor: '.featured-products-slider .featured-products'
            },
        },
        {
            selector: '.featured-products-slider .featured-products',
            initObject: {
                mobileFirst: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
                infinite: false,
                nav: true,
                arrows: true,
                prevArrow: '<div class="arrow left effect-fihs-target"><span class="icon-A"></span></div>',
                nextArrow: '<div class="arrow right effect-fihs-target"><span class="icon-a"></span></div>',
                lazyLoad: "progressive",
                speed: 500,
                cssEase: 'linear',
                asNavFor: '.featured-products-slider .top-details',
            },
            events: {
                afterChange: function(){
                    $(document).trigger("vo-lazy-check");
                }
            }
        },
        {
            selector: '.products-showcase.showcase-6x:not(.custom-showcase) .products-list',
            initObject: {
                mobileFirst: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false,
                infinite: false,
                nav: true,
                arrows: true,
                prevArrow: '<div class="arrow left effect-fihs-target"><span class="icon-A"></span></div>',
                nextArrow: '<div class="arrow right effect-fihs-target"><span class="icon-a"></span></div>',
                lazyLoad: "progressive",
                speed: 500,
                cssEase: 'linear',
                variableWidth: true,
                responsive: [
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 4,
                            slidesToScroll: 4,
                            variableWidth: false
                        }
                    },
                    {
                        breakpoint: 1170,
                        settings: {
                            slidesToShow: 6,
                            slidesToScroll: 6,
                            variableWidth: false
                        }
                    }
                ]
            },
            events: {
                afterChange: function(){
                    $(document).trigger("vo-lazy-check");
                }
            }
        },
        {
            selector: '.products-showcase.showcase-6x.showcase-who-bought .products-list',
            initObject: {
                mobileFirst: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false,
                infinite: false,
                nav: true,
                arrows: true,
                prevArrow: '<div class="arrow left effect-fihs-target"><span class="icon-A"></span></div>',
                nextArrow: '<div class="arrow right effect-fihs-target"><span class="icon-a"></span></div>',
                lazyLoad: "progressive",
                speed: 500,
                cssEase: 'linear',
                variableWidth: true,
                responsive: [
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 4,
                            slidesToScroll: 4,
                            variableWidth: false
                        }
                    }
                ]
            },
            events: {
                afterChange: function(){
                    $(document).trigger("vo-lazy-check");
                }
            }
        },
        {
            selector: '.products-showcase.showcase-6x.showcase-best-sellers .products-list',
            initObject: {
                mobileFirst: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false,
                infinite: false,
                nav: true,
                arrows: true,
                prevArrow: '<div class="arrow left effect-fihs-target"><span class="icon-A"></span></div>',
                nextArrow: '<div class="arrow right effect-fihs-target"><span class="icon-a"></span></div>',
                lazyLoad: "progressive",
                speed: 500,
                cssEase: 'linear',
                variableWidth: true,
                responsive: [
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 4,
                            slidesToScroll: 4,
                            variableWidth: false
                        }
                    }
                ]
            },
            events: {
                afterChange: function(){
                    $(document).trigger("vo-lazy-check");
                }
            }
        },
        {
            selector: '.products-showcase.showcase-4x .products-list',
            initObject: {
                mobileFirst: true,
                slidesToShow: 2,
                slidesToScroll: 1,
                dots: false,
                infinite: false,
                nav: true,
                arrows: true,
                prevArrow: '<div class="arrow left effect-fihs-target"><span class="icon-A"></span></div>',
                nextArrow: '<div class="arrow right effect-fihs-target"><span class="icon-a"></span></div>',
                lazyLoad: "progressive",
                speed: 500,
                cssEase: 'linear',
                variableWidth: true,
                responsive: [
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            variableWidth: false
                        }
                    },
                    {
                        breakpoint: 1170,
                        settings: {
                            slidesToShow: 4,
                            slidesToScroll: 4,
                            variableWidth: false
                        }
                    }
                ]
            },
            events: {
                afterChange: function(){
                    $(document).trigger("vo-lazy-check");
                }
            }
        },
        {
            selector: '.products-showcase.showcase-3x .products-list',
            initObject: {
                mobileFirst: true,
                slidesToShow: 2,
                slidesToScroll: 1,
                dots: false,
                infinite: false,
                nav: true,
                arrows: true,
                prevArrow: '<div class="arrow left effect-fihs-target"><span class="icon-A"></span></div>',
                nextArrow: '<div class="arrow right effect-fihs-target"><span class="icon-a"></span></div>',
                lazyLoad: "progressive",
                speed: 500,
                cssEase: 'linear',
                variableWidth: true,
                responsive: [
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            variableWidth: false
                        }
                    },
                    {
                        breakpoint: 1170,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                            variableWidth: false
                        }
                    }

                ]
            },
            events: {
                afterChange: function(){
                    $(document).trigger("vo-lazy-check");
                }
            }
        },
        {
            selector: '.products-showcase.showcase-2x:not(.fixed-showcase) .products-list',
            initObject: {
                mobileFirst: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false,
                infinite: false,
                nav: true,
                arrows: true,
                prevArrow: '<div class="arrow left effect-fihs-target"><span class="icon-A"></span></div>',
                nextArrow: '<div class="arrow right effect-fihs-target"><span class="icon-a"></span></div>',
                lazyLoad: "progressive",
                speed: 500,
                cssEase: 'linear',
                responsive: [
                    {
                        breakpoint: 1170,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            arrows: false
                        }
                    }

                ]
            },
            events: {
                afterChange: function(){
                    $(document).trigger("vo-lazy-check");
                }
            }
        },
        {
            selector: '.products-showcase.showcase-1-1:not(.fixed-showcase) .products-list',
            initObject: {
                mobileFirst: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false,
                infinite: false,
                nav: true,
                arrows: true,
                prevArrow: '<div class="arrow left effect-fihs-target"><span class="icon-A"></span></div>',
                nextArrow: '<div class="arrow right effect-fihs-target"><span class="icon-a"></span></div>',
                lazyLoad: "progressive",
                speed: 500,
                cssEase: 'linear',
                responsive: [
                    {
                        breakpoint: 1170,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            arrows: false
                        }
                    }

                ]
            },
            events: {
                afterChange: function(){
                    $(document).trigger("vo-lazy-check");
                }
            }
        },
        {
            selector: '.products-showcase.fixed-showcase .products-list',
            initObject: {
                mobileFirst: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false,
                infinite: false,
                nav: true,
                arrows: true,
                prevArrow: '<div class="arrow left effect-fihs-target"><span class="icon-A"></span></div>',
                nextArrow: '<div class="arrow right effect-fihs-target"><span class="icon-a"></span></div>',
                lazyLoad: "progressive",
                speed: 500,
                cssEase: 'linear',
                variableWidth: true,
                responsive: [
                    {
                        breakpoint: 1170,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            arrows: false
                        }
                    }

                ]
            },
            events: {
                afterChange: function(){
                    $(document).trigger("vo-lazy-check");
                }
            }
        },
        {
            selector: '.products-showcase.showcase-image-3 .products-list',
            initObject: {
                mobileFirst: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false,
                infinite: false,
                nav: true,
                arrows: true,
                prevArrow: '<div class="arrow left effect-fihs-target"><span class="icon-A"></span></div>',
                nextArrow: '<div class="arrow right effect-fihs-target"><span class="icon-a"></span></div>',
                lazyLoad: "progressive",
                speed: 500,
                cssEase: 'linear',
                variableWidth: true,
                responsive: [
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            variableWidth: false
                        }
                    },
                    {
                        breakpoint: 1170,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                            variableWidth: false
                        }
                    }

                ]
            },
            events: {
                afterChange: function(){
                    $(document).trigger("vo-lazy-check");
                }
            }
        },
        {
            selector: '.products-showcase.showcase-video-3 .products-list',
            initObject: {
                mobileFirst: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false,
                infinite: false,
                nav: true,
                arrows: true,
                prevArrow: '<div class="arrow left effect-fihs-target"><span class="icon-A"></span></div>',
                nextArrow: '<div class="arrow right effect-fihs-target"><span class="icon-a"></span></div>',
                lazyLoad: "progressive",
                speed: 500,
                cssEase: 'linear',
                responsive: [
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2
                        }
                    },
                    {
                        breakpoint: 1170,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3
                        }
                    }

                ]
            },
            events: {
                afterChange: function(){
                    $(document).trigger("vo-lazy-check");
                }
            }
        },
        {
            selector: '.products-showcase.showcase-image-2 .products-list',
            initObject: {
                mobileFirst: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false,
                infinite: false,
                nav: true,
                arrows: true,
                prevArrow: '<div class="arrow left effect-fihs-target"><span class="icon-A"></span></div>',
                nextArrow: '<div class="arrow right effect-fihs-target"><span class="icon-a"></span></div>',
                lazyLoad: "progressive",
                speed: 500,
                cssEase: 'linear',
                variableWidth: true,
                responsive: [
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            variableWidth: false
                        }
                    }
                ]
            },
            events: {
                afterChange: function(){
                    $(document).trigger("vo-lazy-check");
                }
            }
        },
        {
            selector: '.products-showcase.showcase-video-2 .products-list',
            initObject: {
                mobileFirst: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false,
                infinite: false,
                nav: true,
                arrows: true,
                prevArrow: '<div class="arrow left effect-fihs-target"><span class="icon-A"></span></div>',
                nextArrow: '<div class="arrow right effect-fihs-target"><span class="icon-a"></span></div>',
                lazyLoad: "progressive",
                speed: 500,
                cssEase: 'linear',
                responsive: [
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2
                        }
                    }
                ]
            },
            events: {
                afterChange: function(){
                    $(document).trigger("vo-lazy-check");
                }
            }
        },

    ];

    function _init() {
        $(document).ready(function () {

            _initCarousels();

            $('.book-types-items ul li').click(function(){
                var url = $(this).attr('data-link');
                if (url) {
                    window.location.href = url;
                }
            });

            $('.showcase-6x .image-position img').on("vo-lazy-check-appear", function() {
                var _this = $(this),
                    loadCallback = function(){
                        var elemHeight = _this.height(),
                            currentHeight = _this.closest(".product-image").height();

                        if (elemHeight > currentHeight) {
                            var gallery = _this.closest('.showcase-6x'),
                                newHeight = elemHeight+"px";
                            gallery.find(".product-image").each(function(){
                                $(this).css("height", newHeight);
                            });
                        }
                    };

                    _this.on("load", function () {
                        loadCallback();
                    });

            });

        });
    }

    function _initCarousels() {

        modules.main.initCarousels(carousels);
    }

    _init();

    return {};
})();
